<template>
  <div class="e-form e-form--login">
    <!-- Title -->
    <div class="e-form__title">
      <h1 class="e-form__login-title">{{ $t('system.login.title') }}</h1>
      <h2 class="e-form__login-subtitle">
        {{
          counselingCenterName
            ? counselingCenterName
            : $t('system.login.subtitle')
        }}
      </h2>
    </div>

    <el-form ref="loginForm" :model="loginForm" :rules="rules">
      <!-- Errormessages -->
      <el-alert v-if="error" :title="error" type="error" />

      <el-alert v-if="success" :title="success" type="success" />

      <el-alert
        v-if="errorWithLink"
        :title="$t('system.attention')"
        type="error"
      >
        {{ errorWithLink.prepend }}
        <router-link :to="{ name: errorWithLink.routeName }">
          {{ errorWithLink.linkText }}
        </router-link>
        {{ errorWithLink.append }}
      </el-alert>
      <!-- /Errormessages -->

      <!-- Row -->
      <el-form-item :label="$t('system.login.username')" prop="username">
        <el-input
          ref="usernameInput"
          v-model="loginForm.username"
          :placeholder="$t('system.login.username')"
          name="username"
        />

        <div class="e-form__icon">
          <IconUser />
        </div>

        <div class="e-form__help">
          <router-link :to="{ name: 'forgotUsername' }" tabindex="10">
            {{ $t('system.login.forgotUsername') }}
          </router-link>
        </div>
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.login.password')" prop="password">
        <el-input
          v-model="loginForm.password"
          :placeholder="$t('system.login.password')"
          type="password"
          name="password"
        />

        <div class="e-form__icon">
          <IconLock />
        </div>

        <div class="e-form__help">
          <router-link :to="{ name: 'forgotPassword' }" tabindex="11">
            {{ $t('system.login.forgotPassword') }}
          </router-link>
        </div>
      </el-form-item>

      <!-- Row -->
      <div class="e-form__row e-form__row--submit">
        <el-button
          :loading="loading"
          type="secondary"
          size="large"
          native-type="submit"
          @click.prevent="submitForm('loginForm')"
        >
          {{ $t('system.login.login') }}
        </el-button>

        <router-link
          v-if="!(counselingCenter && counselingCenter.registrationClosed)"
          :to="{ name: 'register', query: $route.query }"
          class="e-form__link"
        >
          {{ $t('system.login.register') }}
        </router-link>
        <el-button v-else class="e-form__link e-form__link--disabled" type="ghost" :disabled="true">
          {{ $t('system.login.register') }}
        </el-button>
      </div>
      <div v-if="counselingCenter && counselingCenter.registrationClosed" class="closed-text" >
        <IconRegistrationClosed class="registration-closed-icon"/> <div v-html="counselingCenter.registrationClosedLoginText" />
      </div>
    </el-form>
  </div>
</template>

<script>
import api from 'api'
import { userActions } from 'mixins'
import { mapActions, mapGetters } from 'vuex'

// Icons
import IconUser from 'assets/icons/user.svg'
import IconLock from 'assets/icons/lock.svg'
import IconRegistrationClosed from 'assets/icons/exclamation_info.svg'

export default {
  name: 'FormLogin',
  components: {
    IconUser,
    IconLock,
    IconRegistrationClosed
  },
  mixins: [userActions],
  data() {
    return {
      loading: false,
      loginForm: {
        username: '',
        password: ''
      },
      rules: {},
      error: false,
      errorWithLink: false,
      success: false
    }
  },
  computed: {
    ...mapGetters(['chatIsOpen', 'counselingCenterId', 'counselingCenters']),
    counselingCenter() {
      if (!this.counselingCenterId) {
        return undefined
      }
      return this.counselingCenters
        .filter(
          counselingCenter => counselingCenter.id === this.counselingCenterId
        )
        .pop()
    },
    counselingCenterName() {
      return this.counselingCenter && this.counselingCenter.name ? this.counselingCenter.name : ''
    }
  },
  mounted() {
    if (this.$route.query.error) {
      if (this.$route.query.error === 'messages.users.invalidConfirmToken') {
        this.errorWithLink = {
          prepend: this.$t('messages.user.invalidConfirmToken.prepend'),
          linkText: this.$t('messages.user.invalidConfirmToken.linkText'),
          routeName: 'resendConfirmMail',
          append: this.$t('messages.user.invalidConfirmToken.append')
        }
      } else {
        this.error = this.$t(this.$route.query.error)
      }
    }

    if (this.$route.query.success) {
      this.success = this.$t(this.$route.query.success)
    }

    if (this.$refs && this.$refs.usernameInput) {
      this.$refs.usernameInput.focus()
    }
  },
  methods: {
    ...mapActions(['storeAnnouncement']),

    submitForm(formName) {
      if (!navigator.cookieEnabled) {
        this.error = this.$t(this.$t('messages.errors.noCookies'))
      } else if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.makeLogin()
          } else {
            return false
          }
        })
      }
    },

    makeLogin: async function() {
      this.loading = true
      this.error = false
      this.errorWithLink = false

      try {
        const response = await api.call('userLogin', false, {
          username: this.loginForm.username,
          password: this.loginForm.password,
          pausentaste:
            new URLSearchParams(window.location.search).get('r') ===
            'pausentaste'
        })

        if (response.data && response.data.redirect) {
          window.location.href = response.data.redirect
          return
        }

        if (response.data && response.data.length > 0)
          this.storeAnnouncement(response.data[0])
        this.$router.push({ name: 'loginLanding' })
        api.call('afterLogin')
      } catch (e) {
        if (e.messages) {
          if (e.messages[0] === 'messages.user.account.unconfirmed') {
            this.errorWithLink = {
              prepend: this.$t('messages.user.account.unconfirmed.prepend'),
              linkText: this.$t('messages.user.account.unconfirmed.linkText'),
              routeName: 'resendConfirmMail',
              append: this.$t('messages.user.account.unconfirmed.append')
            }
          } else {
            this.error = this.$t(e.messages[0])
          }
        } else {
          this.$error(e)
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '_assets/loginform';
</style>
