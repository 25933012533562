<template>
  <div class="e-module e-module--limited e-module--shadow">
    <div class="e-form e-form--register">
      <!-- Title -->
      <div class="e-form__title">
        <h1>{{ $t('system.resetPassword.title') }}</h1>
      </div>

      <div class="e-form__content" v-html="$t('system.resetPassword.body')" />

      <el-form
        ref="forgotPasswordForm"
        :model="forgotPasswordForm"
        :rules="rules"
        method="post"
      >
        <!-- Errormessages -->
        <el-alert
          v-for="(item, index) in errors.messages"
          :key="index"
          :title="$t('system.attention')"
          type="error"
        >
          <div>{{ $t(item) }}</div>
        </el-alert>
        <!-- /Errormessages -->

        <!-- Successmessage -->
        <el-alert
          v-if="formSuccess"
          :title="$t('messages.user.forgotPassword.success')"
          type="success"
        />
        <!-- /Errormessages -->

        <!-- Row -->
        <el-form-item :label="$t('system.register.password')" prop="password">
          <el-input
            v-model="forgotPasswordForm.password"
            :placeholder="$t('system.register.password')"
            type="password"
            name="password"
          />
        </el-form-item>

        <!-- Row -->
        <el-form-item :label="$t('system.register.repeat')" prop="password2">
          <el-input
            v-model="forgotPasswordForm.password2"
            :placeholder="$t('system.register.passwordRepeat')"
            type="password"
            name="password2"
          />
        </el-form-item>

        <!-- Row -->
        <div class="e-form__row e-form__row--submit">
          <router-link
            :to="{ name: 'index' }"
            class="e-btn e-btn--ghost e-btn--large"
          >
            {{ $t('system.back') }}
          </router-link>

          <el-button
            :loading="loading"
            type="secondary"
            size="large"
            native-type="submit"
            @click.prevent="submitForm('forgotPasswordForm')"
          >
            {{ $t('system.forgotPassword.submit') }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from 'api'
import { userActions } from 'mixins'

export default {
  mixins: [userActions],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.user.password.empty')))
      } else {
        if (this.forgotPasswordForm.password2 !== '') {
          this.$refs.forgotPasswordForm.validateField('password2')
        }
        callback()
      }
    }

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.user.password.empty')))
      } else if (value !== this.forgotPasswordForm.password) {
        callback(new Error(this.$t('messages.user.password.dontFit')))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      forgotPasswordForm: {
        password: '',
        password2: ''
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        password2: [{ validator: validatePass2, trigger: 'blur' }]
      },
      errors: [],
      formSuccess: false
    }
  },
  methods: {
    submitForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.sendForm()
          } else {
            return false
          }
        })
      }
    },

    sendForm: async function() {
      this.loading = true
      this.formSuccess = false
      this.errors = []

      try {
        const response = await api.call('resetPassword', false, {
          password: this.forgotPasswordForm.password,
          password2: this.forgotPasswordForm.password2,
          token: this.$route.params.token
        })

        if (response.status === 200) {
          this.formSuccess = true
          this.forgotPasswordForm.password = ''
          this.forgotPasswordForm.password2 = ''
        }
      } catch (e) {
        this.errors = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
// @import '_assets/loginform';
</style>
